
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";

import { Drivers, Storage } from "@ionic/storage";

import Menu from "@/components/Menu.vue";

//import { useRouter } from "vue-router";

export default defineComponent({
    name: "App",
    components: {
        IonApp,
        IonRouterOutlet,
        Menu,
    },
    setup() {
        const storage = new Storage({
            name: "__mydb",
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
        });

        async function createStore() {
            await storage.create();
        }

        createStore();
    },
    /*setup() {
    const router = useRouter();
    console.log("inside App.vue");
    if (
      localStorage.getItem("userInfo") != null &&
      localStorage.getItem("ricordami") != null
    ) {
      console.log(localStorage.getItem("ricordami"));
      router.replace("/tabs/");
    }
  },*/
});
