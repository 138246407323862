
import { useRouter } from "vue-router";

import { ref } from "vue";

import { IonMenu, IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonList, IonLabel, IonIcon, menuController } from "@ionic/vue";

import {
    documents,
    time,
    person,
    clipboard,
    car,
    copy,
    ticket,
    wallet,
    card,
    people,
    book,
    informationCircle,
    documentText,
    listCircle,
    hourglass,
    newspaper,
    calendar,
    cube,
} from "ionicons/icons";

export default {
    components: {
        IonMenu,
        IonHeader,
        IonContent,
        IonToolbar,
        IonTitle,
        IonItem,
        IonList,
        IonLabel,
        IonIcon,
    },
    setup() {
        const router = useRouter();
        const userID = ref(null);
        const dipendenteID = ref(null);
        const userType = ref(null);

        // console.log(localStorage.getItem("userInfo"));
        userID.value = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id : null;
        dipendenteID.value = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).dipendenti_id : null;
        userType.value = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).dipendenti_tipologia : null;

        const changePage = (pageName) => {
            menuController.close("app-menu");
            router.push(`${pageName}`);
        };

        const checkLocalStorage = () => {
            userID.value = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id : null;
            dipendenteID.value = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).dipendenti_id : null;
            userType.value = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).dipendenti_tipologia : null;
        };

        const menuWillOpen = () => {
            checkLocalStorage();
        };

        return {
            documents,
            time,
            person,
            clipboard,
            hourglass,
            car,
            router,
            copy,
            documentText,
            ticket,
            wallet,
            people,
            listCircle,
            card,
            book,
            newspaper,
            calendar,
            cube,
            informationCircle,
            changePage,
            userType,
            menuWillOpen,
        };
    },
};
