import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import Tabs from "../views/Tabs.vue";
import Login from "../views/Login.vue";
//import ClienteDetail from '../views/ClienteDetail.vue'
import { pageAfterLogin } from "../custom_config.js";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/login",
    },
    {
        path: "/login",
        component: Login,
        beforeEnter: (to, from, next) => {
            const user = localStorage.getItem("userInfo");
            const ricordami = localStorage.getItem("ricordami");
            if (user && ricordami) {
                //next({ path: "/tabs/" });
                next({ path: `/${pageAfterLogin}` });
            } else {
                next();
            }
        },
    },
    {
        path: "",
        redirect: `/${pageAfterLogin}`,
    },
    {
        //Presenze
        path: "/presenze",
        //component: () => import("@/views/Presenze.vue"),
        component: () => import("@/custom/presenze/Presenze.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Documenti
        path: "/documenti",
        name: "Documenti",
        component: () => import("@/views/Documenti.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Richieste
        path: "/richieste",
        name: "Richieste",
        component: () => import("@/views/Richieste.vue"),
        meta: { requiresAuth: true },
    },
    {
        //BancaOre
        path: "/banca",
        name: "BancaOre",
        component: () => import("@/views/BancaOre.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Automezzi
        path: "/automezzi",
        name: "Automezzi",
        component: () => import("@/views/Automezzi.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Nota Spese
        path: "/spese",
        name: "NotaSpese",
        component: () => import("@/views/NotaSpese.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Rimborsi km
        path: "/rimborsi",
        name: "Rimborsi",
        component: () => import("@/views/Rimborsi.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Interventi
        path: "/interventi",
        name: "Interventi",
        component: () => import("@/custom/interventi/Interventi.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Tickets
        path: "/tickets",
        name: "Tickets",
        component: () => import("@/custom/ticket/Tickets.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Timesheet
        path: "/timesheet",
        name: "Timesheet",
        component: () => import("@/views/Timesheet.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Clienti (CUSTOM)
        path: "/clienti",
        name: "Clienti",
        component: () => import("@/custom/clienti/Clienti.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Progetti
        path: "/progetti",
        name: "Progetti",
        component: () => import("@/views/Progetti.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Leads
        path: "/leads",
        name: "Leads",
        component: () => import("@/views/Leads.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Sondaggi
        path: "/sondaggi",
        name: "Sondaggi",
        component: () => import("@/views/Sondaggi.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Confindustria
        path: "/conf",
        name: "Confindustria",
        component: () => import("@/views/Confindustria.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Programma Lavoro
        path: "/programma",
        name: "Calendario",
        component: () => import("@/custom/calendario_squadre/Calendario.vue"),
        meta: { requiresAuth: true },
    },
    /* {
        //Programma Lavoro
        path: "/programma",
        name: "ProgrammaLavoro",
        component: () => import("@/views/ProgrammaLavoro.vue"),
        meta: { requiresAuth: true },
    }, */
    {
        //Ordini
        path: "/ordini",
        name: "OrdiniInterni",
        component: () => import("@/views/OrdiniInterni.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Profilo
        path: "/profilo",
        name: "Profilo",
        component: () => import("@/views/Profilo.vue"),
        meta: { requiresAuth: true },
    },
    /*{
        path: "/tabs/",
        component: Tabs,
        meta: { requiresAuth: true },
        children: [
            {
                path: "",
                redirect: "/tabs/tab1",
            },
            {
                path: "tab1",
                component: () => import("@/views/Tab1.vue"),
            },
            {
                path: "tab1/:id",
                //component: ClienteDetail,
                name: "ClienteDetail",
                component: () => import("@/views/ClienteDetail.vue"),
            },
            {
                path: "tab1/:id/commessa/:id_commessa",
                name: "CommessaDetail",
                component: () => import("@/views/CommessaDetail.vue"),
            },
            {
                path: "tab2",
                component: () => import("@/views/Tab2.vue"),
            },
            {
                path: "tab2/:id",
                name: "InterventoDetail",
                component: () => import("@/views/InterventoDetail.vue"),
            },
            {
                path: "tab2/:id/firma-cliente",
                name: "FirmaCliente",
                component: () => import("@/views/FirmaCliente.vue"),
                //props: true
            },
            {
                path: "tab2/:id/firma-operatore",
                name: "FirmaOperatore",
                component: () => import("@/views/FirmaOperatore.vue"),
                //props: true
            },
            {
                path: "tab3",
                component: () => import("@/views/Tab3.vue"),
            },
            {
                path: "tab3/:id",
                component: () => import("@/views/TicketDetail.vue"),
            },
            {
                path: "tab4",
                component: () => import("@/views/Tab4.vue"),
            },
            {
                path: "/firma-cliente",
                component: () => import("@/views/FirmaCliente.vue"),
                props: true,
            },
            //Modulo HR
            {
                path: "presenze",
                name: "Presenze",
                component: () => import("@/views/Presenze.vue"),
            },
            {
                path: "richieste",
                name: "Richieste",
                component: () => import("@/views/Richieste.vue"),
            },
            {
                path: "documenti",
                name: "Documenti",
                component: () => import("@/views/Documenti.vue"),
            },
            {
                path: "squadre",
                component: () => import("@/views/CalendarioSquadre.vue"),
            },
        ],
    },*/
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const user = localStorage.getItem("userInfo");
    //const ricordami = localStorage.getItem("ricordami");
    /*console.log(ricordami);
  console.log("!ricordami: ", !ricordami)
  console.log("!user: ", !user)*/
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (user == null) {
            next({
                path: "/login",
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
